import React, { useEffect, useState } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import { Inter } from 'next/font/google';

// 配置字体
const inter = Inter({
  subsets: ['latin'], // 指定需要的字符子集
  weight: ['400', '500', '600', '700'], // 指定需要的字体权重
  display: 'swap', // 优化字体显示的加载方式
});

import NProgress from 'nprogress';
import '@/styles/mixins.less';
import '@/styles/fonts.css';
import '@/styles/globals.less';
import 'nprogress/nprogress.css';
import { App as AntdApp } from 'antd';
import ConfigProvider from '@/context/ConfigProvider';
import withTheme from '@/theme';
import '@/lib/hooks/common/firebaseConfig/init';
import { appWithTranslation } from 'next-i18next';
import { Locale } from 'antd/lib/locale';
import { CurrentLanguage } from '@/shared/language';
import UserProvider from '@/context/UserProvider';
import dynamic from 'next/dynamic';
// import Head from 'next/head';
import CouponButton from '@/components/Common/coupon/CouponButton';
// import { fetchCouponInfo } from '@/lib/service';
// import { debounce } from 'lodash-es';
import 'maplibre-gl/dist/maplibre-gl.css'; // 确保 MapLibre 的样式生效

import { useWindowSize } from 'react-use';
import GlobalLoading from '@/components/Common/GlobalLoading';
import { isProd } from '@/shared/app-common';
import Login from '@/components/Login';


Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeError', NProgress.done);
Router.events.on('routeChangeComplete', NProgress.done);

require('../styles/ins-theme.less');

// const Login = dynamic(() => import('@/components/Login'), { ssr: false });
const CookieConsentContent = dynamic(() => import('@/components/Common/CookieConsent'), {
  ssr: false,
});
const ArticleBottomAlert = dynamic(() => import('@/components/Common/ArticleBottomAlert'), {
  ssr: false,
});

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<
  P = {
    dehydratedState?: unknown;
  },
> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [loc, setLoc] = useState<Locale>();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();
  const { width } = useWindowSize()

  useEffect(() => {
    setLoc(CurrentLanguage(router.locale).locale);
  }, [router.locale]);

  useEffect(() => {
    // 确保在浏览器环境中动态导入 vConsole
    if (typeof window !== 'undefined' && !isProd) {
      import('vconsole').then((module: any) => {
        const VConsole = module.default;
        const vConsole = new VConsole();
        console.log('vConsole initialized');
      });
    }

  }, [])

  return withTheme(
    <div className={inter.className}>
      <AntdApp>
        <ConfigProvider>
          <UserProvider>
            {/* <Head>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
              />
            </Head> */}

            {getLayout(<Component {...pageProps} />)}

            <Login />
            {/* <LoginResult /> */}
            <GlobalLoading />
            <ArticleBottomAlert />
            {width > 768 && <CookieConsentContent />}
            <CouponButton />
          </UserProvider>
        </ConfigProvider>
      </AntdApp>
    </div>,
    loc,
  );
}

export default appWithTranslation(App);
