import { Spin } from 'antd';
import { useUserProvider } from '@/context/UserProvider';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

const GlobalLoading = () => {
    const { isLoginLoading } = useUserProvider();
    const router = useRouter();

    const getContent = useMemo(() => {
        if (!isLoginLoading && router?.query?.authLoading !== 'true') return null;

        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.6)',
                    zIndex: 99999,
                }}
            >
                <Spin size="large" spinning={true} />
            </div>
        )
    }, [isLoginLoading, router?.query?.authLoading])

    return (<>{getContent}</>);
};

export default GlobalLoading;